import * as React from "react";
import Container from "../components/Container/Container";
import TextLayout from "../layouts/TextLayout";

const TermsOfUsePage = () => {
  return (
    <TextLayout
      pageTitle={"Terms of Use | Omnia"}
      headerFixedOnMobile
      hideAdditionalSection
      title={"Terms of Use"}
    >
      <Container textLayout>
        <div className="inner-page-wrap__content">
        <h1>Terms of Use of OMNIA Protocol</h1>
                  
                  <p>These Terms of Use (hereinafter referred to as "Terms") apply to the access and use of production version v1.0 of
                       the node provider (RPC) OMNIA Protocol and BETA version of node onboarding, including the dashboard, nodes, smartcontracts, nfts and all of it's components
                         (hereinafter referred to as
                      “Protocol”) provided by Privacy Infrastructure Provider OÜ (registry code: 16296221; address: Estonia, Harju maakond, Tallinn, Kesklinna linnaosa, Jõe tn 3-301, 10151) (hereinafter referred to as “OMNIA” or “our”) available
                      via the webpage
                      <a href="https://omniatech.io/" target="_blank" rel="nofollow noopener noreferrer">https://omniatech.io/</a>
                      and any mobile application (hereinafter referred to as “Website”). These Terms constitute an
                      agreement between the customer (hereinafter referred to as “You” or “Your”) and OMNIA and
                      govern Your access to and use of the Site and Services (hereinafter referred to as "Production v1.0 & BETA version" or “Service”) provided by OMNIA and is an agreement between OMNIA and You or the
                      entity You represent. It will be assumed that You have read all of these Terms before You
                      apply for any Services from OMNIA.
                  </p>
                    
                  <p>
                      The sole purpose of BETA verison is to test the protocol which provides the access the blockchain, to obtain feedback
                      on its performance, and to identify defects (hereinafter referred to as “Testing”). You
                      expressly acknowledge that the BETA version is a test product and may contain bugs, errors,
                      and other problems that could cause loss of data stored in the platform, inconsistent
                      performance, and other failures including but not limited to systems crashes, interruptions,
                      and data loss. You are advised to use caution and not to rely in any way on the performance
                      and functioning of the BETA version. The BETA version is made available to You by the use of OMNIA Tokens (or OMNIA Test Tokens) as vouchers
                      and no warranty shall be provided for the BETA version, except for the operability of
                      the BETA version.
                      The sole purpose of Production v1.0 version is to give access to RPC nodes to access the blockchain to obtain feedback
                      on its performance, and to identify defects (hereinafter referred to as Using). You
                      expressly acknowledge that the Production v1.0 version is a first production ready and may contain bugs, errors,
                      and other problems that could cause loss of data stored in the platform, inconsistent
                      performance, and other failures including but not limited to systems crashes, interruptions,
                      and data loss. You are advised to use caution and not to rely in any way on the performance
                      and functioning of the Production v1.0 version. The v1.0 version is made available to You by the use of OMNIA Tokens (or OMNIA Test Tokens) as vouchers
                      and no warranty shall be provided for the v1.0 version, except for the operability of
                      the v1.0 version.
                  </p>
                 
                  <p>
                      We do not guarantee that the full Protocol will be developed to reach any subsequent development
                      stage (e.g. release version) or that any subsequent versions will have the same or similar
                      functionality and characteristics to the ones that the  Production v1.0 & BETA has. To maintain and develop the
                      Protocol we may collect limited and anonymized information and performance data.
                  </p>
                    
                    
                  <h2> We may not be held responsible for any damages you may have because of any failures of the
                      Protocol.</h2>
                      
                  <p>Please read these Terms carefully before using the Site or Services. By using the Site and
                      clicking a button or checkbox to accept the Services (hereinafter referred to as “Effective
                      Date”), You (1) accept and agree to these Terms and (2) consent to the collection, use,
                      disclosure, and other handling of information as described in our Privacy Policy. If You do
                      not agree to the Terms, then You may not access or use the Services.</p>
                      
                      

                  <h2>1. Acceptance and changes to Terms </h2>
                    
                    <p>
                          Any and all terms, conditions, licenses, limitations, and obligations contained
                          within and on the Website are incorporated into these Terms by reference, including,
                          without limiting the generality of the foregoing, the following Website policies and
                          pages: the Privacy Policy; Risk disclaimer and any other documents that might be
                          announced in the Website. In the event of any inconsistency between these Terms and any
                          other pages, policies, terms, conditions, licenses, limitations, or obligations
                          contained within or on the Website, these Terms shall prevail.
                    </p>
                    
               <p>
                          By creating an account on the Website or by using any of the Services or any
                          associated websites, APIs, you acknowledge that you have read, understand, and
                          completely agree to these Terms in effect from time to time. If you disagree with these
                          Terms or with any subsequent amendments, changes, or updates, you may not use any of the
                          Services; your only recourse in the case of disagreement is to stop using all of the
                          Services.
               </p>
               
                <p>
                          These Terms may be amended, changed, or updated by OMNIA at any time and without
                          prior notice to you. You should check back often to confirm that your copy and
                          understanding of these Terms is current and correct. Your non-termination or continued
                          use of any Services after the effective date of any amendments, changes, or updates
                          constitutes your acceptance of these Terms, as modified by such amendments, changes, or
                          updates.
                        </p>
              

                  <h2>2. Use of OMNIA Website and Services</h2>

                  
                     <p>
                          You may access and use the Services in accordance with these Terms. You agree to
                          comply with the Terms and all laws, rules, and regulations applicable in Estonia to Your
                          use of the Service.
                        </p>
                        
                        <p>
                          OMNIA Protocol is a secure, compliant and private infrastructure protocol for securely accessing the blockchain, so that no single point of failure will ever disrupt blockchain applications integrating with it.
                        </p>
                        
                        <p>
                          Using OMNIA Protocol  carries no rights, express or implied, other than the limited
                          right to use Protocol as a means to enable access and interaction with the OMNIA Services,
                          if and to the extent that the Services are successfully completed and deployed.
                        </p>
                        
                        <p>
                          To use the Services, You need to open an account on the Website (hereinafter referred to
                          as “Account”) to be able to access the Services. You have first had to choose either to
                          generate an endpoint to access the blockchain or either to add a resource to the pool of nodes. You are required to open an
                          account and You must complete the registration process by providing us with current,
                          complete, and accurate information as prompted by the applicable registration form. You
                          will also may be required to interact via web3 libraries or other wallets. You are entirely
                          responsible for maintaining the confidentiality of Your password and account and for any
                          and all activities that occur under Your Account.
                        </p>
                        
                        <p>
                          You must keep Your Account’s password and own wallet password/pin safe and not shared with third-parties. We are
                          not
                          responsible for unauthorized access to Your Account. You agree to notify OMNIA
                          immediately
                          of any unauthorized use of Your account or any other breach of security. OMNIA will
                          not
                          be
                          liable for any losses You incur as a result of someone else using Your password/pin or
                          account,
                          either with or without Your knowledge. However, You could be held liable for losses
                          incurred
                          by OMNIA or another party due to someone else using Your account or password. You
                          may
                          not
                          use anyone else’s account at any time, without the permission of the account holder.
                        </p>
                        
                        <p>
                          OMNIA may, in its sole discretion, refuse to allow you to create an Account, or
                          limit the number of Accounts that a single user may create and maintain or terminate an
                          Account at any time.
                        </p>
                        
                        <p>
                          In order to use certain features of the Services, you may be required to provide
                          OMNIA with certain personal information for Know Your Client (KYC) purposes, including,
                          but not limited to, your name, address, telephone number, e-mail address, date of birth,
                          taxpayer identification number, government identification number, and information
                          regarding your bank account (e.g., financial institution, account type, routing number,
                          and account number). In submitting this or any other personal information as may be
                          required, you verify that the information is accurate and authentic, and you agree to
                          update OMNIA if any information changes. You hereby authorize OMNIA to, directly or
                          through third parties make any inquiries OMNIA considers necessary to verify your
                          identity and/or protect against fraud, including to query identity information contained
                          in public reports (e.g., your name, address, past addresses, or date of birth), to query
                          account information associated with your linked bank account (e.g., name or account
                          balance), and to take action OMNIA reasonably deems necessary based on the results of
                          such inquiries and reports. You further authorize any and all third parties to which
                          such inquiries or requests may be directed to fully respond to such inquiries or
                          requests.
                        </p>
                        
                        <p>
                          Once the Account is activated and You start using the Service You have the option
                          to make generate and endpoint based on a subscription or to join as node provider.
                        </p>
                        
                        <p>
                          Using the node providers are available in v1.0 version of OMNIA Protocol but without the posilibity to join as a node provider which is in BETA at the moment.
                          The access to use and onboard the node providers is made through the OMNIA tokens (hereinafter both referred to as “Tokens”) or OMNIA Test Tokens. 
                        </p>
                        
                        <p>
                          If You use OMNIA token for accesing the node providers then You need to fully understand and
                          acknowledge p 8 of these Terms.
                        </p>             
                        
                        
                        <p>
                          If OMNIA stops or suspends the Service for security reasons, Your account might
                          become
                          inaccessible by You and no further transaction will be possible during the
                          inactivity
                          period.
                        </p>
               
                        
                        <p>
                          The Account will have a login history where information about login dates, devices
                          from which the Account was accessed from and respective IP addresses will be visible to
                          You.
                      </p>

                      

                  <h2>3. Your Responsibilities</h2>

                        
                      <p>
                          Except to the extent caused by our breach of these Terms, (a) You are responsible for
                          all activities that occur in Your Account, regardless of whether the activities are
                          authorized by You or undertaken by You, Your employees, or a third party (including Your
                          contractors, agents or end-users), and (b) OMNIA and our affiliates are not responsible
                          for unauthorized access to Your Account.
                      </p>
                      
                      <p>
                          You will ensure that Your Use of the Services does not violate any applicable law. You
                          are solely responsible for Your Use of the Services.
                        </p>
                        
                        <p>
                          You are responsible for properly configuring and using the Services and otherwise taking
                          appropriate action to secure, protect, and backup Your Accounts and Your Content in a
                          manner that will provide appropriate security and protection, which might include the
                          use of encryption.
                        </p>
                        
                        <p>
                          You shall immediately report any malfunction or defect found while using the Service.
                          The report can be made to the following e-mail address: info@omniatech.io.
                        </p>
                        
                        <p>
                          You are liable for the correctness of the information You have submitted to OMNIA during
                          registration. In case of changes in the information, You shall update Your Account.
                        </p>
                        
                        <p>
                          When accessing Services, You shall not: (i) negatively interfere with other customers;
                          (ii) damage, disable, or in any way disrupt the Website; (iii) conduct, pay for,
                          support, or in any way be involved in any illegal activities, including but not limited
                          to money laundering, terrorist financing, fraud, illegal gambling, illegal weapons sale
                          and drug trafficking; (iv) use any automated means or interface to access Webpage or to
                          extract data; (v) use another customer’s account; (vi) provide false or misleading
                          information to us.
                        </p>
                        

                  <h2>4. Liability. Warranties. Indemnification. Suspension</h2>

                  
                      <p>
                         To the extent permitted by law, OMNIA will not be liable for any damages, losses of
                          profit, usage or data, loss of business, loss of business, failure to use the Service,
                          customers misunderstanding of the Service or any other loss originated from negligence
                          or fraud or otherwise arising out of or connected to the Service or these Terms.
                      </p>
                        
                        <p>
                          You shall indemnify and hold harmless OMNIA, its affiliates, contractors, licensors, and
                          respective shareholders, members, directors, officers, employees, attorneys, agents, and
                          suppliers from any claim, damage, lawsuit, tort, cost, or expenses.
                      </p>
                            
                            <p>
                          The Services are provided on an “as is” and “as available” basis without any warranty or
                          representation expressed or implied. OMNIA does not make any representations or give
                          warranties that the access to the Service, use of the Account, the functionality of the
                          wallet will be continuous, uninterrupted, timely, or error-free.
                            </p>
                            
                            <p>
                          You understand and agree that OMNIA shall not be liable in connection with any force
                          majeure event, including labor disputes or other industrial disturbances, electrical,
                          telecommunications, hardware, software or other utility failures, software or smart
                          contract bugs or weaknesses, earthquakes, storms, or other nature-related events,
                          blockages, embargoes, riots, strikes, acts or orders of government authority, acts of
                          terrorism or war, technological change, changes in interest rates or virtual currencies
                          or other monetary conditions, and, for the avoidance of doubt, changes to any
                          blockchain-related protocol.
                            </p>
                            
                            <p>
                          OMNIA may suspend Your right to access or use any portion or all of the Services
                          immediately upon notice to You if we determine: (a) Your use of the Services (i) poses a
                          security risk to the Services or any third party, (ii) could adversely impact our
                          systems, the Services or the systems of any other user, (iii) could subject us, our
                          affiliates, or any third party to liability, or (iv) could be fraudulent; (b) You are,
                          or any end-user is, in breach of these Terms ; (c) for entities, You have ceased to
                          operate in the ordinary course, made an assignment for the benefit of creditors or
                          similar disposition of Your assets, or become the subject of any bankruptcy,
                          reorganization, liquidation, dissolution or similar proceeding.
                          </p>
                    
                  <h2>5. Intellectual property (IP) protection</h2>

                          
                      <p>
                          Unless otherwise clearly specified, all copyright and other IP rights of our Service
                          (hereinafter referred to as “Content”) constitute our proprietary rights and are
                          protected by international laws and national IP and copyright laws.
                      </p>
                      
                      <p>
                          Subject to the terms of Terms, we grant You a personal, limited, revocable,
                          non-exclusive, non-sublicensable, non-transferable license to do the following: (a)
                          access and use the Services solely in accordance with these Terms; and (b) copy and use
                          Our Content solely in connection with Your permitted use of the Services.
                        </p>
                        
                        <p>
                          The use of such protected material without a license or specific authorization violates
                          copyright and IP rights and shall result in criminal and/or civil penalties.
                        </p>
                        
                        <p>
                          You may view, download and print the documents from the Service solely for your personal
                          use and own information purposes.
                        </p>
                        
                        <p>
                          Neither You nor any end-user will use the Services in any manner or for any purpose
                          other than as expressly permitted by these Terms. Except as expressly authorized,
                          neither You nor any end-user will, or will attempt to (a) modify, distribute, alter,
                          tamper with, repair, or otherwise create derivative works of any Content included in the
                          Services, (b) reverse engineer, disassemble, or decompile the Services or apply any
                          other process or procedure to derive the source code of any software included in the
                          Services (except to the extent applicable law doesn’t allow this restriction), (c)
                          access or use the Services in a way intended to avoid incurring fees or exceeding usage
                          limits or quotas, (d) use scraping techniques to mine or otherwise scrape data, or (e)
                          resell or sublicense the Services unless otherwise agreed in writing. You will not
                          misrepresent or embellish the relationship between OMNIA and You (including by
                          expressing or implying that we support, sponsor, endorse, or contribute to You or Your
                          business endeavors). You will not imply any relationship or affiliation between OMNIA
                          and You except as expressly permitted by this Agreement.
                        </p>
                        
                        <p>
                          If You provide any Suggestions to OMNIA, we and our affiliates will be entitled to use
                          the Suggestions without restriction. You hereby irrevocably assign to us all right,
                          title, and interest in and to the Suggestions and agree to provide us any assistance we
                          require to document, perfect, and maintain our rights in the Suggestions.
                        </p>
                        
                        <p>
                          OMNIA and the OMNIA logos, the URLs representing the Website, trade names, wordmarks,
                          and design marks (hereinafter referred to as "OMNIA Marks") are trademarks of OMNIA or
                          its related companies. In addition, all page headers, custom graphics, design, button
                          icons, scripts, source code, content are copyrighted by OMNIA. You agree not to
                          appropriate, copy, display or use the OMNIA Marks or other content without express,
                          prior, written permission to do so. Unless otherwise indicated, all materials on OMNIA
                          are © OMNIA.
                        </p>
                        
                        <p>
                          You acknowledge and agree that any materials, including but not limited to questions,
                          comments, feedback, suggestions, ideas, plans, notes, drawings, original, or creative
                          materials or other information or commentary you provide on the Website or one of OMNIA
                          ‘s social media accounts, regarding OMNIA or the Services (collectively hereinafter
                          referred to as "Feedback") that are provided by you, whether by email, posting to the
                          Website or otherwise, are non-confidential and will become the sole property of OMNIA.
                          OMNIA will own exclusive rights, including all intellectual property rights, and will be
                          entitled to the unrestricted use and dissemination of such Feedback for any purpose,
                          commercial or otherwise, without acknowledgment or compensation to you.
                        </p>
                        

                  <h2>6. Your Representations &amp; Warranties</h2>

                        
                        <h4>
                          You represent and warrant to OMNIA as follows:</h4>
                       
                       <p>
                                  that, if you are an individual customer, you are 18 years of age or older
                                  and that you have the capacity to contract under applicable law
                              </p>
                              
                            <p>
                                  that, if you are not an individual customer, you have the requisite power and
                                  authority to sign and enter into binding agreements for and on behalf of the
                                  customer;
                                </p>
                                
                              <p>
                                  that you understand the risks associated with using the Site and Services and
                                  you are not otherwise prohibited by law from using the Site and Services;
                                </p>
                                
                              <p>
                                  that you will not use the Site or any Services in order to disguise the proceeds
                                  of, or to further, any breach of applicable laws or regulations, or to deal in
                                  any contraband cryptocurrencies or proceeds;
                                </p>
                                
                              <p>
                                  that you will not trade on the Site or use any Services with anything other than
                                  cryptocurrencies that have been legally obtained by you and that belong to you;
                                </p>
                                
                              <p>
                                  that you will not falsify any account registration details provided to OMNIA;
                                </p>
                                
                              <p>
                                  that you will not falsify or materially omit any information or provide
                                  misleading information requested by OMNIA in the course of, directly or
                                  indirectly relating to, or arising from your activities on the Site or use of
                                  any Services, including at registration;
                                </p>
                                
                              <p>
                                  that any trading or other instructions received or undertaken through your login
                                  credentials or from your authorized e-mail address on file with OMNIA are deemed
                                  to be valid, binding, and conclusive, and that OMNIA may act upon those
                                  instructions without any liability or responsibility attaching to it; and,
                                </p>
                                
                              <p>
                                  that you will fairly and promptly report all income associated with your
                                  activity on the Site pursuant to applicable law and pay any and all taxes
                                  eligible thereon;
                                </p>
                                
                              <p>
                                  that you understand that cryptocurrencies are often described in exceedingly
                                  technical language that requires a comprehensive understanding of applied
                                  cryptography and computer science in order to appreciate inherent risks. The
                                  ability to use cryptocurrencies on OMNIA’s Services does not indicate approval
                                  or disapproval of the underlying technology regarding any cryptocurrencies, and
                                  should not be used as a substitute for your own understanding of the risks
                                  specific to each cryptocurrency. OMNIA gives you no warranty as to the
                                  suitability of the cryptocurrencies used under these Terms and assumes no
                                  fiduciary duty in OMNIA‘s relations with you.
                                </p>
                                
                              <p>
                                  You agree that OMNIA is not responsible for determining whether or which laws
                                  may
                                  apply to your transactions, including tax law. You are solely responsible for
                                  reporting and paying any taxes arising from your use of the Services.
                            </p>
                            
                       
                
                  <h2>7. The Website and Services Accuracy</h2>

                  
                  <p>
                          Although OMNIA intends to provide accurate and timely information on the Website and
                          while providing Services, the Website (including, without limitation, Services) may not
                          always be entirely accurate, complete, or current and may also include technical
                          inaccuracies or typographical errors. In an effort to continue to provide you with as
                          complete and accurate information as possible, information may be changed or updated
                          from time to time without notice, including without limitation information regarding
                          OMNIA‘s policies, products, and services. Accordingly, you should verify all information
                          before relying on it, and all decisions based on information contained on the Website
                          are your sole responsibility and OMNIA shall have no liability for such decisions. Links
                          to third-party materials (including without limitation websites) may be provided as a
                          convenience but are not controlled by OMNIA. You acknowledge and agree that OMNIA is not
                          responsible for any aspect of the information, content, or services contained in any
                          third-party materials or on any third-party Websites accessible or linked to the
                          Website.
                  </p>
                  
                  <h2>8. Abandoned Property</h2>

                
                <p>
                          Where you have not logged into your account on the Website for an uninterrupted period
                          of
                          2 years, OMNIA thereafter reserves the right to deem any and all cryptocurrencies
                          that you hold on the Account, to be abandoned, with or without notice to you. OMNIA will
                          try to locate you at the address shown in our records, but if we are unable to, we may
                          be required to deliver any cryptocurrencies to the authorities in certain jurisdictions
                          as unclaimed property. We reserve the right to deduct a dormancy fee or other
                          administrative charges from such unclaimed funds, as permitted by applicable law.
                      </p>
                      

                  <h2>9. Regulations. Compliance with local laws.</h2>

                  
                  <p>
                      It is Your responsibility to comply with local laws in respect to the legal usage of the
                          Service and the regulatory qualification of virtual currencies in Your jurisdiction.
                  </p>
                  

                  <h2>10. Jurisdiction. Applicable law. Disputes</h2>
                  
                  <p>
                          These Terms have been drawn up in accordance with the laws of the Republic of Estonia
                          and the application, interpretation, and termination shall be governed by the laws of
                          the Republic of Estonia.
                  </p>
                  
                  <p>
                          Jurisdiction of the United States of America (including all territories of this country) and sanctioned countries.
                          is specifically excluded from these Terms. We reserve the right to change the
                          jurisdiction of OMNIA and these Terms at any time at our own discretion, as well as use
                          any parent companies, subsidiaries, and/or other affiliated companies for the execution
                          of these Terms, our products, and other activities related to our business.
                        </p>
                        
                        <p>
                          Any disputes regarding the use of the Service and/or in connection with these Terms
                          shall
                          be settled through negotiations. If the Parties fail to resolve the dispute through
                          negotiation, the disputes shall be settled by Harju Maakohus (Harju County Court),
                          pursuant to the procedure provided by the law of the Republic of Estonia.
                        </p>
                        
                        <p>
                          You and OMNIA agree that any party hereto may bring claims against the others only on an
                          individual basis and not as a plaintiff or class member in any purported class or
                          representative action or proceeding. No adjudicator may consolidate or join more than
                          one person’s or party’s claims and may not otherwise preside over any form of a
                          consolidated, representative, or class proceeding. Any relief awarded to any one OMNIA
                          user cannot and may not affect any other OMNIA user.
                        </p>
                        
                        
                  <h2>11. Term. Termination</h2>

                  
                  <p>
                          The term of these Terms will commence on the Effective Date and will remain in effect
                          until terminated under this Section 7.
                  </p>
                  
                  <p>
                          You may terminate the agreement with OMNIA at any time on the basis provided by law.
                        </p>
                        
                        <h4>
                          You agree that OMNIA may terminate the agreement with You and suspend or cancel Your
                          Account in case of, but not limited to:</h4>
                          
                          
                              <p> - non-conformity to the requirements from these Terms;</p>
                              <p> - a requirement from any applicable law to which we are subject in any
                                  jurisdiction;</p>
                              <p> - an order from a court, or governmental authority;</p>
                              <p> - unusual, unauthorized or fraudulent activity in Your Account;</p>
                              <p> - false, misleading, inaccurate, incomplete, or outdated information;</p>
                              <p> - any other reasons that OMNIA deems relevant for termination of the
                                  agreement between You and OMNIA and suspension or cancelation of Your Account.
                              </p>
                    
                        
                        <p>
                          OMNIA shall have the right to file claims against You or Your legal representative for
                          compensation.
                        </p>
                        
                        <p>
                          If an event of force majeure occurs, the party injured hereto by the other’s inability
                          to perform may elect to suspend the agreement between You and OMNIA, in whole or part,
                          for the duration of the force majeure circumstances.
                        </p>
                        
                        <p>
                          Upon termination, suspension, or cancellation on the basis of the previous clauses, You
                          will be denied access to the Account temporarily or permanently.
                        </p>
                        

                  <h2>12. Disclaimer. Risk</h2>
                        
                 <p>
                          OMNIA makes no representations, warranties, or guarantees to you of any kind. The
                          Service is provided “as is” and “as available”. Except to the extent prohibited by law
                          or to the extent any statutory rights apply that cannot be excluded, limited, or waived.
                          Your use of the Service shall be at Your own sole risk. OMNIA makes no representations
                          or warranties including any implied or express warranties of merchantability,
                          satisfactory quality, fitness or a particular purpose, non-infringement arising out of
                          any course of dealing or usage of trade that the Service will be uninterrupted,
                          error-free or free of harmful components, and that any content will be secure or not
                          otherwise lost or altered. OMNIA does not warrant that Your use of Service will be
                          uninterrupted or error-free, nor does OMNIA warrant that it will review Your content for
                          accuracy or that it will preserve or maintain Your content or end-user data without
                          loss. OMNIA will not be liable for delays, interruptions, Service failures, or other
                          problems inherent in the use of the internet and electronic communications or other
                          systems outside the reasonable control of OMNIA. You may have other statutory rights,
                          but the duration of statutorily required warranties, if any, will be limited to the
                          shortest period permitted by law. To the extent permitted by law, OMNIA is not
                          responsible for any delays, delivery of failures, or any other loss or damage resulting
                          from (i) the transfer of data over public communications networks and facilities
                          including the internet; (ii) personal injury or property damage or any nature whatsoever
                          resulting from Your access to and use of OMNIA Services and/or any all personal
                          information and/or financial information stored therein; (iii) any loss of Your data or
                          content from the OMNIA Services; or (iv) any delay or delivery failure on the part of
                          any other Service provider not contracted by OMNIA, and You acknowledge that the Service
                          may be subject to limitations, delays and other problems inherent in the use of such
                          communication facilities. You acknowledge that OMNIA cannot guarantee the absolute
                          prevention of cyber-attacks such as hacking, spyware, and viruses. Accordingly, IMNIA
                          shall not be liable for any unauthorized disclosure, loss, or destruction to make or
                          pass on any representation or warranty on behalf of OMNIA to any third party. OMNIA does
                          not warrant, endorse, guarantee or assume responsibility for any product or Service
                          offered by third-party through the Website or OMNIA Services, and OMNIA shall not be a
                          party to or in any way responsible for monitoring and any transaction between You and
                          third-party providers of product or Services.
                          The present information on website is dedicated for marketing purposes only, meaning it is not intended to be legally binding and is delivered “as is”. Nothing in the website shall be deemed to constitute a prospectus of any sort, or an invitation, or a solicitation for investment, nor does it in any way pertain to an offering, invitation, or solicitation of an offer to purchase any security, ownership right, stake, share, future revenue, company participation, or other equivalent rights in any jurisdiction. Information in this document also does not constitute a recommendation by any person to purchase tokens, currencies, or any other cryptographic assets; neither the Project has authorized any person to provide any recommendations of such kind.
                      </p>
                      
                      <p>
                          OMNIA Services rely on emerging technologies, such as Ethereum. Some Services are
                          subject
                          to increased risk through Your potential misuse of things as public/private key
                          cryptography. By using the Services You explicitly acknowledge and accept these
                          heightened risks.
                        </p>
                        
                        <p>
                          Since You can use Ethereum for withdrawal and deposit please note that crypto assets and
                          markets are decentralized and not regulated. The Crypto asset market is determined only
                          by supply and demand and is often highly unpredictable and volatile. The price of a
                          crypto asset is usually not transparent and highly speculative and susceptible to market
                          manipulation. In the worst-case scenario, crypto-assets could be rendered worthless.
                        </p>
                        
                        <p>
                          Accordingly, crypto assets should be seen as an asset class with extremely high risks.
                          You should not deal in these products unless You have the necessary knowledge and
                          expertise, You understand these products’ characteristics, and Your exposure to risk
                        </p>
                        
                        <p>
                          Since blockchain is an independent public or private peer-to-peer network and is not
                          controlled in any way or manner by us, we shall not be responsible for any failure
                          and/or mistake and/or error and/or breach which shall occur in blockchain or in any
                          other networks in which the crypto asset are being issued. You will be bound and subject
                          to any change and/or amendments in the blockchain system and subject to any applicable
                          law which may apply to the blockchain. We make no representation or warranty of any
                          kind, express or implied, statutory or otherwise, regarding the blockchain functionality
                          nor for any breach of security in the blockchain. You should be aware of the risks that
                          may result from any system failure which could mean that Your order may be delayed or
                          fail.
                        </p>
                        
                        <p>
                          You acknowledge that there are risks associated with the system over the internet
                          including, but not limited to, the failure of hardware, software, and internet
                          connections, the risk of malicious software introduction, the risk that third parties
                          may obtain unauthorized access to information and/or assets (including Your crypto
                          asset/token) stored on Your behalf, cyber-attack, the crypto asset network failure (such
                          as Blockchain), computer viruses, communication failures, disruptions, errors,
                          distortions or delays You may experience when trading via the Services, howsoever
                          caused, spyware, scareware, Trojan horses, worms or other malware that may affect Your
                          computer or other equipment, or any phishing, spoofing or other attacks. You should also
                          be aware that SMS and email Services are vulnerable to spoofing and phishing attacks and
                          should use care in reviewing messages purporting to originate from us.
                      </p>
                      

                  <h2>13. Miscellaneous</h2>
                
                <p>
                          The defined concepts that are written in the upper case first letter in the Terms are
                          used in the indicated meaning unless the context indicates a different meaning.
                      </p>
                      
                      <p>
                          These Terms set forth the entire understanding between OMNIA and You with respect to the
                          Service. You agree to rely only on the Terms alone. These Terms supersede any previous
                          statements made by OMNIA or individuals associated with the OMNIA.
                        </p>
                        
                        <p>
                          The failure by us to enforce any provision of these Terms will not constitute a present
                          or future waiver of such provision nor limit our right to enforce such provision at a
                          later time. All waivers by us must be in writing to be effective.
                        </p>
                        
                        <p>
                          If any term, clause, or provision of these Terms is held unlawful, void, or
                          unenforceable, then that term, clause, or provision will be severable from this
                          agreement and will not affect the validity or enforceability of any remaining part of
                          that term, clause or provision, or any other term, clause or provision of these Terms
                          and the invalid condition shall be replaced by a valid condition as close as possible to
                          the outcome and the detail of the replaced condition.
                        </p>
                        
                        <p>
                          No failure of OMNIA to exercise any right, power, or privilege under these Terms is
                          deemed to be a waiver thereof, nor any partial exercise of a right, power, or privilege
                          should preclude the exercise of the entire or other rights, power or privilege.
                        </p>
                        
                        <p>
                          You may not assign any of the rights conferred to You under these Terms without prior
                          written consent from OMNIA. OMNIA may assign any right or obligation under these Terms
                          without any notice to or consent from You.
                        </p>
                        
                        <p>
                          These Terms are provided in English. Any translation has the sole purpose of being
                          convenient for You. In case of any inconsistency, contradiction, or doubt, the English
                          version of these Terms shall prevail.
                        </p>
                        
                        <p>
                          You can contact us, if You have some question, feedback, or complaint related to our
                          Service, by sending OMNIA a message through <a href="https://omniatech.io/" target="_blank" rel="nofollow noopener noreferrer">https://omniatech.io/</a> by
                          sending an e-mail to info@omniatech.io
                        </p>
                        

        </div>
      </Container>
    </TextLayout>
  );
};

export default TermsOfUsePage;
