import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Container from "../components/Container/Container";

const TextLayout = ({
  children,
  pageTitle,
  headerFixedOnMobile = false,
  footerSection = "subscribe",
  hideAdditionalSection = false,
  title,
}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/new_logomark_light_180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/new_logomark_light_32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/new_logomark_light_16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/new_logomark_light.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Header headerFixedOnMobile={headerFixedOnMobile} />
      <div className="inner-page-wrap">
        <Container textLayout>
          {title && (
            <div className="page-heading">
              <h1>{title}</h1>
            </div>
          )}
        </Container>

        {children}
      </div>
      <Footer
        footerSection={footerSection}
        hideAdditionalSection={hideAdditionalSection}
      />
    </>
  );
};

export default TextLayout;
